import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import { graphql } from "gatsby"
import Header from "../../components/ui/base/layout/header"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import CollectionCard from "../../components/ui/base/CollectionCards"
import Section from "../../components/ui/base/Section"
import classnames from "classnames"
import * as styles from "../tour/index.module.scss"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useTranslation } from "react-i18next"
import { BASE_URL, COLLECTIONS_URL, PHOTO_TOURS_URL } from "../../util/constants"

const CollectionsPage = ({data}) => {
  const { i18n: l  } = useTranslation();
  const collectionsPage = data.strapiCollectionPage;

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang : l.language }}
        title={collectionsPage.seo.metaTitle}
        description={collectionsPage.seo.metaDescription}
        canonical={`${BASE_URL[l.language]}/${PHOTO_TOURS_URL[l.language]}/${COLLECTIONS_URL[l.language]}/`}
        language={l.language}
      />
      <Header  size={"compact"} videoURL={"viaggio-fotografico-aurora-boreale"} theme="dark">
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant='heading1'>
                  {collectionsPage.title}
                </Typography>
                <Breadcrumbs elements={[
                  {
                    name: "WeShoot",
                    path: "/",
                  },
                  {
                    name: "Viaggi Fotografici",
                    path: `/${PHOTO_TOURS_URL[l.language]}/`,
                  },
                  {
                    name: "Collezioni",
                    path: `/${PHOTO_TOURS_URL[l.language]}/${COLLECTIONS_URL[l.language]}/`,
                  }
                ]} />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section
        variant={"white"}>
        {data?.allStrapiCollection.nodes && data?.allStrapiCollection.nodes.length > 0 && (
            <div className={classnames([styles.collectionContainer])}>
                {data.allStrapiCollection.nodes.map((collection, i) => {
                  if (collection.locale === l.language){
                    return (
                      <CollectionCard collection={collection} key={i} />
                    )
                  }else{
                    return <></>
                  }

                })}
            </div>
        )}
      </Section>
    </>
  )
}


export const query = graphql`
    query CollectionsQuery($locale: String!) {
    strapiCollectionPage {
    seo {
      metaTitle
      metaDescription
    }
    title
    description
  }
    
    allStrapiCollection(filter: {locale: {eq: $locale}}) {
          nodes {
            name
            slug
            excerpt
            locale
            image {
              localFile {
                ...ImageFragment
              }
            }
          }
    }
  }
`;

export default CollectionsPage
