// extracted by mini-css-extract-plugin
export var alternativeNavbar = "index-module--alternativeNavbar--IuLy+";
export var show = "index-module--show--l+Dgk";
export var left = "index-module--left--8ulQW";
export var info = "index-module--info--2xMVi";
export var icon = "index-module--icon--dBj1e";
export var title = "index-module--title--E1aQD";
export var days = "index-module--days--sacGN";
export var center = "index-module--center--JecRO";
export var right = "index-module--right--QYnUf";
export var price = "index-module--price--xqbkt";
export var header = "index-module--header--FbCr0";
export var cta_book = "index-module--cta_book--rQhQ+";
export var tourContainer = "index-module--tourContainer--M+WzB";
export var topGallery = "index-module--topGallery--v0SYC";
export var gatsbyImageWrapper = "index-module--gatsby-image-wrapper--0MRyT";
export var mainTitle = "index-module--main-title--BGurk";
export var description = "index-module--description--Nvdyx";
export var riepilogueCard = "index-module--riepilogueCard--ykPR4";
export var duration = "index-module--duration--OejWK";
export var next_departure = "index-module--next_departure--Y0L16";
export var label = "index-module--label--SL+nU";
export var number = "index-module--number--Kp9RU";
export var deposit = "index-module--deposit--0C6k-";
export var whatsincluded = "index-module--whatsincluded--bwMBk";
export var includedContainer = "index-module--includedContainer--KGkp2";
export var included = "index-module--included--4LgwJ";
export var footer = "index-module--footer--cR5pA";
export var highlights = "index-module--highlights--4nfbH";
export var highlight = "index-module--highlight--UP34o";
export var text = "index-module--text--4MQIP";
export var h5 = "index-module--h5--MgrCp";
export var coaches = "index-module--coaches--DLrCf";
export var coaches_container = "index-module--coaches_container--Qd-l4";
export var reviews = "index-module--reviews--L-nd5";
export var session = "index-module--session--i0HV-";
export var gallery = "index-module--gallery--0IOgI";
export var thingsNeeded = "index-module--thingsNeeded--XdHZG";